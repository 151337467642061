import React, { useContext, createContext } from "react"
import { ResourceSetContext } from "gatsby-singita-theme/src/context/ResourceSetContext"

export const LodgeContext = createContext({
  lodgeData: {},
})

export const LodgeContextProvider = ({ children, lodgeData, slug }) => {
  const { getResourcesForComponent } = useContext(ResourceSetContext)
  const {
    privateVillas: { color },
  } = getResourcesForComponent("lodges")
  const lodgeDataCopy = { ...lodgeData }
  if (slug === "private-villas") {
    lodgeDataCopy.lodgeColor = color
  }
  return (
    <LodgeContext.Provider value={{ lodgeData: lodgeDataCopy }}>
      {children}
    </LodgeContext.Provider>
  )
}
