import { navigate } from 'gatsby';
import ModalRoutingContext from './src/context/ModalRoutingContext';
import get from 'lodash/get';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Modal from 'react-modal';

const withoutPrefix = (path) => {
  const prefix =
    typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__;

  return path.slice(prefix ? prefix.length : 0);
};

const ConditionalModal = ({ props, element, modalProps }) => {
  const { location, pageResources } = props;

  const pathname = useRef(null);
  const modalPage = useRef(null);
  const originPage = useRef(null);

  const contentRef = useRef(null);

  const queryRef = useRef(null); // Ref to hold the

  useEffect(() => {
    if (props.location.pathname !== pathname.current) {
      pathname.current = props.location.pathname;
      if (get(props.location, 'state.isModal', false)) {
        modalPage.current = props;
      } else {
        originPage.current = props;
      }
    }
  }, [props]);

  useLayoutEffect(() => {
    if (
      location.pathname !== pathname.current &&
      get(location, 'state.isModal') &&
      contentRef.current
    ) {
      // modal remains open after path change, so make sure it's scrolled to the top
      contentRef.current.scrollTop = 0;
    }
  }, [location.pathname]);

  const isModal = !!originPage.current && get(location, 'state.isModal', false);

  pageResources.component = pageResources.component
    ? pageResources.component.default
      ? pageResources.component.default
      : pageResources.component
    : pageResources.component;

  const pageElement = isModal
    ? React.createElement(originPage.current.pageResources.component, {
        ...originPage.current,
        key: originPage.current.pageResources.page.path,
      })
    : React.createElement(pageResources.component, {
        ...props,
        key: pageResources.page.path,
      });

  let modalElement = null;

  if (isModal) {
    modalElement = React.createElement(pageResources.component, {
      ...props,
      key: pageResources.page.path,
    });
  } else if (modalPage.current) {
    modalElement = React.createElement(
      get(modalPage.current, 'pageResources.component'),
      {
        ...modalPage.current,
        key: get(modalPage.current, 'pageResources.page.path'),
      },
    );
  }
  const prevModalUrl = get(location, 'state.prevModalUrl', null);

  const handleRequestClose = () => {
    navigate(
      withoutPrefix(prevModalUrl || originPage.current.location.pathname),
      {
        state: {
          noScroll: true,
        },
      },
    );
  };

  const params = new URLSearchParams(location.search);
  const query = params.get('query');
  let queryChange = false;
  if (query !== queryRef.current) {
    queryChange = true;
    queryRef.current = query;
  }
  return (
    // <React.Suspense fallback={null}>
    queryChange ? (
      element
    ) : (
      <>
        {pageElement}
        <Modal
          onRequestClose={handleRequestClose}
          contentRef={(node) => (contentRef.current = node)}
          {...modalProps}
          isOpen={!!isModal}
        >
          {modalElement ? (
            <React.Fragment key={location.key}>
              <ModalRoutingContext.Provider
                value={{
                  prevModalUrl,
                  isModal: true,
                  closeTo: originPage.current
                    ? withoutPrefix(
                        prevModalUrl || originPage.current.location.pathname,
                      )
                    : '/',
                }}
              >
                {modalElement}
              </ModalRoutingContext.Provider>
            </React.Fragment>
          ) : null}
        </Modal>
      </>
    )
    // </React.Suspense>
  );
};

export const WrapPageElement = ({ element, props }, opts) => {
  const { modalProps, pageAnimation } = opts;
  return (
    <ConditionalModal
      props={props}
      element={element}
      modalProps={modalProps}
      pageAnimation={pageAnimation}
    />
  );
};
