import { css, Global, ThemeProvider } from '@emotion/react';
import { theme } from '@singita/components';
import React from 'react';
import { MediaContextProvider } from './src/Media';
import { AvailabilityProvider } from './src/context/AvailabilityContext';
import { ContentModalProvider } from './src/context/ContentModalContext';
import { ResourceSetProvider } from './src/context/ResourceSetContext';
import { VideoProvider } from './src/context/VideoContext';
import { NavbarContextProvider } from './src/context/NavbarContext';
import globalStyles from './src/global.css';
import reactDayPickerStyles from '@singita/components/dist/style.css';

export const wrapRootElement = ({ element }) => (
  // disableDynamicMediaQueries is used to prevent the media queries from being recalculated on window resize events
  // This is useful for SSR as the window object is not available
  // We disabled it to avoid hydration issues
  <MediaContextProvider disableDynamicMediaQueries>
    <ResourceSetProvider>
      <AvailabilityProvider>
        <NavbarContextProvider>
          <ContentModalProvider>
            <VideoProvider>
              <ThemeProvider theme={theme}>
                <Global
                  styles={css`
                    @font-face {
                      font-family: 'morion';
                      src: url('/fonts/morion-200-normal-d9e7336e2.eot'); /* IE9 Compat Modes */
                      src:
                        url('/fonts/morion-200-normal-d9e7336e2.eot?#iefix')
                          format('embedded-opentype'),
                        /* IE6-IE8 */
                          url('/fonts/morion-200-normal-d9e7336e2.woff2')
                          format('woff2'),
                        /* Super Modern Browsers */
                          url('/fonts/morion-200-normal-d9e7336e2.woff')
                          format('woff'); /* Modern Browsers */
                      font-weight: 200;
                      font-style: normal;
                      font-display: swap;
                    }

                    /* Morion 400 - Regular */
                    @font-face {
                      font-family: 'morion';
                      src: url('/fonts/morion-400-normal-49c925274.eot'); /* IE9 Compat Modes */
                      src:
                        url('/fonts/morion-400-normal-49c925274.eot?#iefix')
                          format('embedded-opentype'),
                        /* IE6-IE8 */
                          url('/fonts/morion-400-normal-49c925274.woff2')
                          format('woff2'),
                        /* Super Modern Browsers */
                          url('/fonts/morion-400-normal-49c925274.woff')
                          format('woff'); /* Modern Browsers */
                      font-weight: 400;
                      font-style: normal;
                      font-display: swap;
                    }

                    /* Morion 600 - Bold */
                    @font-face {
                      font-family: 'morion';
                      src: url('/fonts/morion-600-normal-6c50942b6.eot'); /* IE9 Compat Modes */
                      src:
                        url('/fonts/morion-600-normal-6c50942b6.eot?#iefix')
                          format('embedded-opentype'),
                        /* IE6-IE8 */
                          url('/fonts/morion-600-normal-6c50942b6.woff2')
                          format('woff2'),
                        /* Super Modern Browsers */
                          url('/fonts/morion-600-normal-6c50942b6.woff')
                          format('woff'); /* Modern Browsers */
                      font-weight: 600;
                      font-style: normal;
                      font-display: swap;
                    }

                    @font-face {
                      font-family: 'Singita2020';
                      src: url('/fonts/icons/icons.eot');
                      src:
                        url('/fonts/icons/icons.eot?#iefix')
                          format('embedded-opentype'),
                        url('/fonts/icons/icons.woff2') format('woff2'),
                        url('/fonts/icons/icons.woff') format('woff'),
                        url('/fonts/icons/icons.ttf') format('truetype'),
                        url('/fonts/icons/icons.otf') format('opentype');
                      font-weight: normal;
                      font-style: normal;
                    }

                    *,
                    *::before,
                    *:after {
                      box-sizing: border-box;
                    }
                    html {
                      box-sizing: border-box;
                      font-family: ${theme.fonts.sans};
                      font-size: 16px;
                    }
                    html,
                    body,
                    div,
                    span,
                    applet,
                    object,
                    iframe,
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p,
                    blockquote,
                    pre,
                    a,
                    abbr,
                    acronym,
                    address,
                    big,
                    cite,
                    code,
                    del,
                    dfn,
                    em,
                    img,
                    ins,
                    kbd,
                    q,
                    s,
                    samp,
                    small,
                    strike,
                    strong,
                    sub,
                    sup,
                    tt,
                    var,
                    b,
                    u,
                    i,
                    center,
                    dl,
                    dt,
                    dd,
                    ol,
                    ul,
                    li,
                    fieldset,
                    form,
                    label,
                    legend,
                    table,
                    caption,
                    tbody,
                    tfoot,
                    thead,
                    tr,
                    th,
                    td,
                    article,
                    aside,
                    canvas,
                    details,
                    embed,
                    figure,
                    figcaption,
                    footer,
                    header,
                    hgroup,
                    menu,
                    nav,
                    output,
                    ruby,
                    section,
                    summary,
                    time,
                    mark,
                    audio,
                    video {
                      margin: 0;
                      padding: 0;
                      border: 0;
                      font-size: 100%;
                      font: inherit;
                      vertical-align: baseline;
                    }
                    article,
                    aside,
                    details,
                    figcaption,
                    figure,
                    footer,
                    header,
                    hgroup,
                    menu,
                    nav,
                    section {
                      display: block;
                    }
                    body {
                      line-height: 1;
                    }
                    ol,
                    ul {
                      list-style: none;
                    }
                    blockquote,
                    q {
                      quotes: none;
                    }
                    blockquote::before,
                    blockquote:after,
                    q::before,
                    q:after {
                      content: '';
                      content: none;
                    }
                    table {
                      border-collapse: collapse;
                      border-spacing: 0;
                    }
                    body {
                      line-height: 1.5;
                      letter-spacing: 0;
                      background-color: #f7fafc;
                    }

                    a {
                      text-decoration: none;
                      color: inherit;
                    }

                    body.no-scroll {
                      overflow-y: hidden;
                    }
                    ${globalStyles}
                    ${reactDayPickerStyles}
                    .icon {
                      display: inline-block;
                      font: normal normal normal 14px / 1 'Singita2020';
                      font-size: inherit;
                      text-rendering: auto;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: grayscale;
                    }
                    .icon-fw {
                      width: 1.28571429em;
                      text-align: center;
                    }

                    .icon-ul {
                      padding-left: 0;
                      margin-left: 2.14285714em;
                      list-style-type: none;
                    }

                    .icon-ul > li {
                      position: relative;
                    }

                    .icon-li {
                      position: absolute;
                      left: -2.14285714em;
                      width: 2.14285714em;
                      top: 0.14285714em;
                      text-align: center;
                    }

                    .icon-li.icon-lg {
                      left: -1.85714286em;
                    }

                    .icon-facebook::before {
                      content: '\\f000';
                    }

                    .icon-twitter-old::before {
                      content: '\\f001';
                    }

                    .icon-pinterest::before {
                      content: '\\f002';
                    }

                    .icon-envelope::before {
                      content: '\\f003';
                    }

                    .icon-print::before {
                      content: '\\f004';
                    }

                    .icon-chevron-right::before {
                      content: '\\f005';
                    }

                    .icon-chevron-left::before {
                      content: '\\f006';
                    }

                    .icon-arrow-right::before {
                      content: '\\f007';
                    }

                    .icon-close::before {
                      content: '\\f008';
                    }

                    .icon-menu::before {
                      content: '\\f009';
                    }

                    .icon-chevron-up::before {
                      content: '\\f00a';
                    }

                    .icon-chevron-down::before {
                      content: '\\f00b';
                    }

                    .icon-shop::before {
                      content: '\\f00c';
                    }

                    .icon-login::before {
                      content: '\\f00d';
                    }

                    .icon-search::before {
                      content: '\\f00e';
                    }

                    .icon-camera::before {
                      content: '\\f00f';
                    }

                    .icon-instagram::before {
                      content: '\\f010';
                    }

                    .icon-vimeo::before {
                      content: '\\f011';
                    }

                    .icon-linkedin::before {
                      content: '\\f012';
                    }

                    .icon-play::before {
                      content: '\\f013';
                    }

                    .icon-dot::before {
                      content: '\\f014';
                    }

                    .icon-grid::before {
                      content: '\\f015';
                    }

                    .icon-list::before {
                      content: '\\f016';
                    }

                    .icon-sound-on::before {
                      content: '\\f017';
                    }

                    .icon-sound-off::before {
                      content: '\\f018';
                    }

                    .icon-google::before {
                      content: '\\f019';
                    }

                    .icon-external-link::before {
                      content: '\\f01a';
                    }

                    .icon-globe::before {
                      content: '\\f01b';
                    }

                    .icon-chevron-right-small::before {
                      content: '\\f01c';
                    }

                    .icon-chevron-left-small::before {
                      content: '\\f01d';
                    }

                    .icon-accommodation::before {
                      content: '\\f01e';
                    }

                    .icon-chevron-double-left::before {
                      content: '\\f01f';
                    }

                    .icon-chevron-double-right::before {
                      content: '\\f020';
                    }

                    .icon-checkin-duo::before {
                      content: '\\f021';
                    }

                    .icon-checkin-duo-color::before {
                      content: '\\f022';
                    }

                    .icon-accommodation-duo-color::before {
                      content: '\\f023';
                    }

                    .icon-accommodation-duo::before {
                      content: '\\f024';
                    }

                    .icon-bell-duo-color::before {
                      content: '\\f025';
                    }

                    .icon-bell-duo::before {
                      content: '\\f026';
                    }

                    .icon-checklist-duo-color::before {
                      content: '\\f027';
                    }

                    .icon-children-duo-color::before {
                      content: '\\f028';
                    }

                    .icon-children-duo::before {
                      content: '\\f029';
                    }

                    .icon-contact-duo-color::before {
                      content: '\\f02a';
                    }

                    .icon-contact-duo::before {
                      content: '\\f02b';
                    }

                    .icon-design-duo-color::before {
                      content: '\\f02c';
                    }

                    .icon-design-duo::before {
                      content: '\\f02d';
                    }

                    .icon-directions-duo-color::before {
                      content: '\\f02e';
                    }

                    .icon-directions-duo::before {
                      content: '\\f02f';
                    }

                    .icon-health-duo-color::before {
                      content: '\\f030';
                    }

                    .icon-health-duo::before {
                      content: '\\f031';
                    }

                    .icon-notepad-duo-color::before {
                      content: '\\f032';
                    }

                    .icon-notepad-duo::before {
                      content: '\\f033';
                    }

                    .icon-weather-duo-color::before {
                      content: '\\f036';
                    }

                    .icon-weather-duo::before {
                      content: '\\f037';
                    }

                    .icon-checklist-duo::before {
                      content: '\\f038';
                    }

                    .icon-conservation-duo-color::before {
                      content: '\\f039';
                    }

                    .icon-conservation-duo::before {
                      content: '\\f03a';
                    }

                    .icon-photos-duo-color::before {
                      content: '\\f03b';
                    }

                    .icon-photos-duo::before {
                      content: '\\f03c';
                    }

                    .icon-promotion-duo-color::before {
                      content: '\\f03d';
                    }

                    .icon-promotion-duo::before {
                      content: '\\f03e';
                    }

                    .icon-airaccess-duo-color::before {
                      content: '\\f041';
                    }

                    .icon-airaccess-duo::before {
                      content: '\\f042';
                    }

                    .icon-rates-duo-color::before {
                      content: '\\f043';
                    }

                    .icon-rates-duo::before {
                      content: '\\f044';
                    }

                    .icon-jet-duo::before {
                      content: '\\f045';
                    }

                    .icon-jet-duo-color::before {
                      content: '\\f046';
                    }

                    .icon-pdf::before {
                      content: '\\f047';
                    }

                    .icon-plus::before {
                      content: '\\f04b';
                    }

                    .icon-minus::before {
                      content: '\\f04c';
                    }

                    .icon-check::before {
                      content: '\\f04d';
                    }

                    .icon-wiki::before {
                      content: '\\f04e';
                    }

                    .icon-youtube::before {
                      content: '\\f04f';
                    }

                    .icon-angle-right::before {
                      content: '\\f051';
                    }

                    .icon-angle-left::before {
                      content: '\\f052';
                    }

                    .icon-notification::before {
                      content: '\\f053';
                    }

                    .icon-download::before {
                      content: '\\f054';
                    }

                    .icon-gift::before {
                      content: '\\f055';
                    }

                    .icon-spotify::before {
                      content: '\\f056';
                    }

                    .icon-tiktok::before {
                      content: '\\f057';
                    }

                    .icon-gallery-thumbnails-light::before {
                      content: '\\f058';
                    }

                    .icon-grid-2-light::before {
                      content: '\\f059';
                    }

                    .icon-grid-light::before {
                      content: '\\f05a';
                    }

                    .icon-grid-4-light::before {
                      content: '\\f05b';
                    }

                    .icon-twitter::before {
                      content: '\\f05c';
                    }

                    .icon-copy::before {
                      content: '\\f05d';
                    }

                    .icon-podcast::before {
                      content: '\\f05e';
                    }
                  `}
                />

                {element}
              </ThemeProvider>
            </VideoProvider>
          </ContentModalProvider>
        </NavbarContextProvider>
      </AvailabilityProvider>
    </ResourceSetProvider>
  </MediaContextProvider>
);
