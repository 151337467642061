import React from "react"
import { I18nextProvider } from "react-i18next"
import { LanguageProvider } from "gatsby-singita-theme/src/context/LanguageContext"
import { LodgeContextProvider } from "gatsby-singita-theme/src/context/LodgeContext"

import i18n from "gatsby-singita-theme/src/i18n"

export const wrapPageElement = ({ element, props }) => {
  i18n.changeLanguage(props.pageContext.locale ?? "en-US")
  return (
    <I18nextProvider i18n={i18n}>
      <LanguageProvider locale={props.pageContext.locale ?? "en-US"}>
        <LodgeContextProvider
          lodgeData={{ lodgeColor: props.data?.lodge?.color ?? null }}
          slug={props.pageContext.slug}
        >
          {element}
        </LodgeContextProvider>
      </LanguageProvider>
    </I18nextProvider>
  )
}
