import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Helper for populating a JSON with all the translation strings.
// With this, each locale will be populated with namespaces based on the filename
const compileStrings = (ctx) => {
  const compiled = { resources: {} };
  const fileRegex = /\.\/(?<locale>.*)\.json$/;

  ctx.keys().forEach((file) => {
    const { locale } = file.match(fileRegex).groups;

    compiled.resources[locale] = !!compiled.resources[locale]
      ? compiled.resources[locale]
      : {};
    compiled.resources[locale] = ctx(file);
  });

  return compiled;
};

const strings = compileStrings(require.context('./', true, /\w+\.json$/));

i18n.use(initReactI18next).init({
  resources: strings.resources,
  fallbackLng: 'en-US',
  debug: process.env.NODE_ENV === 'development',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true,
  react: {
    useSuspense: true, // Add this if you encounter SSR-related hydration issues
  },
});

export default i18n;
