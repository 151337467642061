export { wrapPageElement } from "./page-wrapper"
export { wrapRootElement } from "./root-wrapper"

let queryRef = null

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const params = new URLSearchParams(location.search)
  const query = params.get("query")
  let queryChange = false
  if (query !== queryRef) {
    queryChange = true
    queryRef = query
  }
  if (queryChange) {
    return false
  }

  if (
    (location.pathname.startsWith("/blog") ||
      location.pathname.startsWith("/author") ||
      location.pathname.startsWith("/careers") ||
      location.pathname.startsWith("/press-room") ||
      location.pathname.startsWith("/awards") ||
      location.pathname.startsWith("/wildlife")) &&
    !!location.search
  ) {
    return false
  }
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), 0)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), 0)
  }
  return false
}

export const onRouteUpdate = () => {
  if (window.location.hash) {
    setTimeout(() => {
      const element = document.querySelector(window.location.hash)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }, 500) // Ensures page is loaded before scrolling
  }
}

export const onClientEntry = async () => {
  if (typeof window.IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }
}
